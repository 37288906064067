<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        screenWidth: 0,
        screenHeigth: 0,
      }
    },
    mounted() {
      
    }
  }
</script>

<style lang="less">
  // @import url('./assets/css/wuwei.css');
  // @import url('./assets/css/normalize.css');

  #app {
    width: 100vw;
    height: 100vh;
  }
</style>
