import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeMon',
    component: () => import('../views/HomeMon')
  },
  // {
  //   path: '/',
  //   name: 'Home3',
  //   component: () => import('../views/Home3')
  // },
  {
    path: '/home-2',
    name: 'Home2',
    component: () => import('../views/Home2')
  },
  {
    path: '/home-3',
    name: 'Home',
    component: () => import('../views/Home')
  },
  {
    path: '/detail/:village',
    component: () => import('@/views/detail'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 4 个牧户 的 智慧牧场智慧屏幕 （红色）
  {
    path: '/demo-makido:val',
    component: () => import('@/views/bigscreenDesigner/demonstrationMakido/index'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },

  // 乌审旗 1 个牧户 的 智慧牧场智慧屏幕 （红色）
  {
    path: '/personal-makido',
    component: () => import('@/views/bigscreenDesigner/demonstrationMakido/personal-bigscreen'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },






  
  //  ================== ppt 设计页面 ===================
  {
    path: '/bigscreen/menuPage',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/menuPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/countyPage',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/countyPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/countyPage2',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/countyPage2'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/countyAnimal',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/countyAnimalPaiHang'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/countyFarmal',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/countyFarmalPaiHang'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 苏木镇 农牧业统计
  {
    path: '/bigscreen/villagePage:val',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/villagePage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 苏木镇 农业统计
  {
    path: '/bigscreen/villageFarmal:val',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/villagePage2'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 苏木镇 牧业统计
  {
    path: '/bigscreen/villageAnimal:val',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/villagePage3'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/yuZhongJiLu',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/yuZhongJiLu'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/fangYiJiLu',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/fangYiJiLu'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/villageFangYiTongJi:val',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/villageFangYiTongJi'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },

  // ---- 嘎查 -----
  {
    path: '/bigscreen/hamletPage:val',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/hamletPage2'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/hamletAnimalPage:val',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/hamletPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/hamletFangYiTongJi:val',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/hamletFangYiTongJi'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/personState:val',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/personState'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/mashineState:val',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/mashineState'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/tuDiLiuZhuan',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/tuDiLiuZhuan'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/tuDiQueQuan',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/tuDiQueQuan'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/tuDiLiuZhuanChart',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/tuDiLiuZhuanChart'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },

  {
    path: '/bigscreen/muHuPage:val',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/muHuPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/oxDetail:val',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/oxDetail'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/siCaoShangPage',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/siCaoShangPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/orderPage',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/orderPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/productPage',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/productPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 用户发送位置后显示点标记
  {
    path: '/bigscreen/dianBiaoJi',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/dianBiaoJi'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bigscreen/qiYe',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/test'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 防疫呼叫
  {
    path: '/bigscreen/fangYiHuJiao',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/fangYiHuJiao'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 防疫诊疗 工作统计
  {
    path: '/bigscreen/gongZuoTongJi',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/gongZuoTongJi'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 后台
  {
    path: '/bigscreen/admin',
    component: () => import('@/views/bigscreenDesigner/showPage/pages/admin'),
    hidden: true,
    meta: {
     requireAuth: true
    }
  },
]

const router = new VueRouter({
  // mode: 'hash',
  routes
})

export default router
